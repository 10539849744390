import { BrowserRouter, Route, Routes } from "react-router-dom";
import Historique from "./pages/History";
import Login from "./pages/Login";
import TableauDeBord from "./pages/Dashboard";
import Settings from "./pages/Settings";
import { useEffect } from "react";
import moment from "moment";
import Contrat from "./pages/Contrat";
import { ModalConfrimation } from "./components/UI/Modal";

function App() {
  useEffect(() => {
    const theme = localStorage.getItem("theme") ? localStorage.getItem("theme") : window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    document.body.classList = theme === "dark" ? "dark bg-gray-800 text-gray-100" : "bg-gray-50 text-gray-600";
    document.body.style = "color-scheme: " + theme + ";";
    document.documentElement.classList = theme === "dark" ? "dark" : "";

    moment.locale("fr");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TableauDeBord />} />
        <Route path="/contrat/:id" element={<Contrat />} />
        <Route path="/history" element={<Historique />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <ModalConfrimation />
    </BrowserRouter>
  );
}

export default App;
