import moment from "moment/moment";
import { useEffect, useState } from "react";
import { methodGet, methodPost } from "../../providers/ApiProvider";
import { getHighlightedText } from "../../providers/Functions";
import { SecondaryOutlineButton } from "../UI/Buttons";
import EditableValue from "../UI/EditableValue";
import Modal from "../UI/Modal";
import SearchBar from "../UI/SearchBar";
import Select from "../UI/Select";
import { Spinner } from "../UI/Spinner";
import MaterielCard from "./MaterielCard";

export default function AddMaterielModal({ id, open, setOpen, onAdded }) {
  const [newProduct, setNewProduct] = useState({
    rate: 0,
    release_date: Math.round(moment().toDate().getTime() / 1000),
    return_date: Math.round(
      moment().set({ h: 18, minutes: 0 }).toDate().getTime() / 1000
    ),
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [addMateriel, setAddMateriel] = useState(0);
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState(null);
  const [productListFiltered, setProductListFiltered] = useState(null);

  useEffect(() => {
    if (productList === null) return;
    let filtered = productList.filter(
      (e) =>
        e.label.toLowerCase().includes(search.toLowerCase()) ||
        e.ref.toLowerCase().includes(search.toLowerCase())
    );
    setProductListFiltered(filtered);
  }, [search, productList]);

  useEffect(() => {
    if (addMateriel === 0 && !productList)
      methodGet("/bonlocationapi/product/get_all?sqlfilters=t.tosell%3D1").then((res) =>
        setProductList(res)
      );
  }, [addMateriel]);

  function modifProduct(e) {
    let newVal = { ...newProduct };
    newVal[e.target.id] = e.target.value;
    setNewProduct(newVal);
  }
  async function handleUploadProduct() {
    if (newProduct.name === undefined || newProduct.name.length < 3)
      return setErrorMessage(
        "Veuillez saisir un nom avec plus de 3 caractères"
      );
    if (newProduct.reference === undefined)
      return setErrorMessage("Veuillez saisir un référence");
    let res = await methodPost(
      "/bonlocationapi/rentalagreements/product/set/" + id,
      newProduct
    );
    setNewProduct({
      rate: 0,
      total_rate: 0,
      release_date: Math.round(moment().toDate().getTime() / 1000),
      return_date: Math.round(
        moment().set({ h: 18, minutes: 0 }).toDate().getTime() / 1000
      ),
    });
    onAdded(res);
    setOpen(false);
  }

  async function handleImportProduct(idProduct) {
    let res = await methodPost(
      "/bonlocationapi/rentalagreements/product/set/" + id,
      {
        id_product: idProduct,
        release_date: Math.round(moment().toDate().getTime() / 1000),
        return_date: Math.round(
          moment().set({ h: 18, minutes: 0 }).toDate().getTime() / 1000
        ),
      }
    );
    onAdded(res);
    setOpen(false);
  }
  return (
    <Modal
      title="Ajouter Matériel à Louer"
      showActionButton={addMateriel === 1}
      onActtionButton={handleUploadProduct}
      errorMessgae={errorMessage}
      open={open}
      setOpen={setOpen}
    >
      {addMateriel === 0 && (
        <>
          <div className="flex justify-between items-center my-6 space-x-4">
            <SearchBar
              placeholder="Rechercher Matériel..."
              onChange={setSearch}
            />
            <SecondaryOutlineButton
              text="Creer Matériel Libre"
              onClick={() => setAddMateriel(1)}
            />
          </div>
          <div className="flex flex-col space-y-2 p-2 max-h-96 overflow-auto">
            {!productListFiltered && <Spinner />}
            {productListFiltered &&
              productListFiltered.map((product) => (
                <div
                  key={product.id + "_import_product"}
                  className="p-4 rounded-xl border flex justify-between items-center border-gray-900 dark:border-gray-200 "
                >
                  <p className="text-xl font-bold">
                    {getHighlightedText(product.label, search)}{" "}
                    <span className="text-base font-normal">
                      {getHighlightedText(product.ref, search)}
                    </span>
                  </p>
                  <div className="flex space-x-2 items-center">
                    <p>
                      €{Math.round(parseFloat(product.price) * 100) / 100}/jour
                    </p>
                    <SecondaryOutlineButton
                      text="Importer"
                      onClick={() => handleImportProduct(product.id)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      {addMateriel === 1 && (
        <div className="grid grid-cols-2 col-span-5 gap-x-24 gap-y-4 p-10">
          <EditableValue
            onChange={modifProduct}
            id="name"
            title="Nom du Produit"
            editing={true}
            small={false}
            placeholder="Nom produit . . ."
          />
          <EditableValue
            onChange={modifProduct}
            id="reference"
            title="Référence"
            editing={true}
            small={false}
            placeholder="Référence . . ."
          />
        </div>
      )}
    </Modal>
  );
}
