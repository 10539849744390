import React, { useEffect, useState } from "react";

export default function EditBox({ title, isEditable, onSave, onChange = null, ...props }) {
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (onChange) onChange(editing);
  }, [editing]);
  return (
    <div className={"block m-1 w-full " + (editing ? "border-b border-dashed bg-gray-50 dark:bg-gray-800" : "")}>
      <div className="flex items-center justify-between px-4 py-2 bg-gray-300 dark:bg-gray-700">
        <h3 className="uppercase font-medium text-base">{title}</h3>
        <div className="flex items-center space-x-2">
          {editing && isEditable && (
            <svg
              onClick={() => {
                onSave();
                setEditing(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.7}
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          )}
          {isEditable && (
            <svg
              onClick={() => setEditing(!editing)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.7}
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={
                  editing
                    ? "M6 18L18 6M6 6l12 12"
                    : "M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                }
              />
            </svg>
          )}
        </div>
      </div>
      <div className="block py-2 px-6 space-y-2">{props.children(editing)}</div>
    </div>
  );
}
