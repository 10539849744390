export default function EditableValue({
  id,
  title,
  value,
  onChange,
  defaultValue = null,
  type = "text",
  placeholder = "saisir...",
  small = true,
  autocomplete = "newPassword",
  editing = false,
}) {
  return (
    <div className={"flex items-center space-x-2 " + (small ? "h-6 text-sm justify-between" : "h-8 text-base")}>
      {title !== undefined && <p className="font-semibold w-full whitespace-nowrap">{title} :</p>}
      {!editing && <p id={"text_" + id} className="whitespace-nowrap text-ellipsis">{value}</p>}
      {editing && (
        <input
          id={id}
          type={type}
          className={
            "relative block appearance-none min-w-fit rounded-md border dark:bg-gray-900 border-gray-300 dark:border-gray-700  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" +
            (small ? "px-3 py-0.5 sm:text-sm " : "px-4 py-2 sm:text-base ")
          }
          onChange={onChange}
          autoComplete={autocomplete}
          defaultValue={defaultValue ? defaultValue : value}
          placeholder={placeholder}
        />
      )}
    </div>
  );
}
