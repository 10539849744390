import moment from "moment/moment";
import { useEffect, useState } from "react";
import { methodGet, methodPost, methodPut } from "../../providers/ApiProvider";
import Modal from "../UI/Modal";
import { Spinner } from "../UI/Spinner";
import ConstatForm from "./ConstatForm";

export default function ConstatModal({ p, open, setOpen, onAdded }) {
  const [show, setShow] = useState(p.startReport === null ? "startReport" : "returnReport");
  const [product, setProduct] = useState(p);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (product["array_options"] === undefined) {
      setLoading(true);
      methodGet("/products/" + product.product_id).then((res) => {
        let modifProduct = { ...product };
        modifProduct.report = {};
        if (res.array_options && res.array_options !== [] && show === "startReport") {
          modifProduct.array_options = Object.keys(res.array_options).map((k) => {
            return { [k.split("options_")[1]]: res.array_options[k] };
          });
          if (modifProduct.array_options.length > 0) {
            modifProduct.array_options = modifProduct.array_options.reduce((a, b) => {
              return { ...a, ...b };
            });
            modifProduct.report["exterior_cleanliness"] = modifProduct.array_options["proprete_exterieure"];
            modifProduct.report["interior_cleanliness"] = modifProduct.array_options["proprete_interieure"];
            modifProduct.report["lights_windows_mirrors"] = modifProduct.array_options["feux_vitres_glaces"];
            modifProduct.report["fuel"] = modifProduct.array_options["carburant"];
            modifProduct.report["body"] = modifProduct.array_options["carroserie"];
            modifProduct.report["document"] = modifProduct.array_options["documents"];
            modifProduct.report["mileage"] = Math.round((modifProduct.mileage ? modifProduct.mileage : modifProduct.array_options["kilometrage"]) * 100) / 100;
            modifProduct.report["hours"] = Math.round((modifProduct.hours ? modifProduct.hours : modifProduct.array_options["heures"]) * 100) / 100;
          }
        } else if (show === "returnReport") {
          modifProduct.report["exterior_cleanliness"] = modifProduct["startReport"]["exterior_cleanliness"];
          modifProduct.report["interior_cleanliness"] = modifProduct["startReport"]["interior_cleanliness"];
          modifProduct.report["lights_windows_mirrors"] = modifProduct["startReport"]["lights_windows_mirrors"];
          modifProduct.report["fuel"] = modifProduct["startReport"]["fuel"];
          modifProduct.report["body"] = modifProduct["startReport"]["body"];
          modifProduct.report["document"] = modifProduct["startReport"]["document"];
          modifProduct.report["mileage"] = modifProduct["startReport"]["mileage"];
          modifProduct.report["hours"] = modifProduct["startReport"]["hours"];
          modifProduct.array_options = modifProduct.report;
        }

        setProduct(modifProduct);
        setLoading(false);
      });
    }
  }, [product]);

  async function handleUploadReports(e) {
    let date = document.getElementById("date_constat").value;
    if (date === "") return setErrorMessage("Veuillez saisir la date");

    setLoading(true);
    await methodPost("/bonlocationapi/rentalagreements/report/" + product.id + "/" + (show === "startReport" ? 1 : 2), {
      ...product.report,
      date: moment(date).toDate().getTime() / 1000,
    });
    if (show === "startReport")
      methodPut("/bonlocationapi/product/" + product.id, {
        release_date: moment(date).toDate().getTime() / 1000,
        hours: product.report.hours,
        mileage: product.report.mileage,
        return_date:
          moment(date).toDate().getTime() >
          moment(product.return_date * 1000)
            .toDate()
            .getTime()
            ? moment(date).set({ h: 18, minutes: 0 }).toDate().getTime() / 1000
            : product.return_date,
      });
    else
      methodPut("/bonlocationapi/product/" + product.id, {
        return_date: moment(date).toDate().getTime() / 1000,
      });

    if (show === "returnReport" && product.product_id !== null)
      await methodPut("/products/" + product.product_id, {
        array_options: {
          options_proprete_exterieure: product.report.exterior_cleanliness,
          options_proprete_interieure: product.report.interior_cleanliness,
          options_carroserie: product.report.body,
          options_feux_vitres_glaces: product.report.lights_windows_mirrors,
          options_documents: product.report.document,
          options_carburant: product.report.fuel,
          options_kilometrage: product.report.hours,
          options_heures: product.report.mileage,
        },
      });

    setLoading(false);
    onAdded({ ...product, [show]: product.report });
    setOpen(false);
  }
  return (
    <Modal
      title={product.name + "( ref. " + product.reference + " )"}
      showFooter={true}
      showActionButton={p[show] === null}
      onActtionButton={handleUploadReports}
      textActionButton="Ajouter constat"
      open={open}
      setOpen={setOpen}
      errorMessgae={errorMessage}
    >
      {loading && <Spinner />}
      {!loading && product.report && (
        <ConstatForm
          product={product}
          constat={product.report}
          setConstat={(report) => setProduct({ ...product, report: report })}
          show={show}
          setShow={setShow}
        />
      )}
    </Modal>
  );
}
