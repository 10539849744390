import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddMaterielModal from "../components/contrat/AddMaterielModal";
import EditAndSaveButtons from "../components/contrat/Edit&SaveButtons";
import EditBox from "../components/contrat/EditBox";
import { askConfirmationModal } from "../components/UI/Modal.jsx";
import MaterielCard from "../components/contrat/MaterielCard";
import Structure from "../components/navigation/Structure";
import { DeleteButton, PrimaryButton, PrimaryButtonOutlined, SecondaryOutlineButton, SuccesButton } from "../components/UI/Buttons";
import EditableValue from "../components/UI/EditableValue";
import Select from "../components/UI/Select";
import { Spinner } from "../components/UI/Spinner";
import { methodDelete, methodGet, methodPost, methodPut } from "../providers/ApiProvider";
import { uncodeStringSpecialChars } from "../providers/Functions";
import MultiConstatModal from "../components/constat/MultiConstatModal";
import ConstatModal from "../components/constat/ConstatModal";

let agencies = null;
let licenses = null;
export default function Contrat() {
  const [contrat, setContrat] = useState(null);
  const [editableContrat, setEditableContrat] = useState();
  const [editableLicense, setEditableLicense] = useState({});
  const [showModalDepart, setShowModalDepart] = useState(false);
  const [agencyOptions, setAgencyOptions] = useState(agencies);
  const [editing, setEditing] = useState(false);
  const [showMaterielModal, setShowMaterielModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInfoConstat, setShowInfoConstat] = useState(null);
  const [editingMaterials, setEditingMaterials] = useState([]);
  const [licenseOptions, setLicenseOptions] = useState(licenses);
  const navigate = useNavigate();

  useEffect(() => {
    if (!contrat)
      methodGet("/bonlocationapi/rentalagreements/" + window.location.pathname.split("contrat/")[1])
        .then((res) => {
          if (res)
            try {
              res.delivery_address = res.delivery_address.replaceAll("{", "").replaceAll("}", "");
              res.delivery_address = res.delivery_address.split("\\,").length > 1 ? res.delivery_address.split("\\,") : res.delivery_address.split(",");
              res.delivery_address = res.delivery_address
                .map((e) => {
                  return {
                    [e.split(":")[0]]: e.split(":")[1].replaceAll("\\", ""),
                  };
                })
                .reduce((a, b) => {
                  return { ...a, ...b };
                });
            } catch (error) {
              res.delivery_address = {
                address: "",
                town: "",
                zip: "",
              };
            }
          setContrat(res);
        })
        .catch((err) => {
          if (err.error.code === 404) navigate("/");
        });
    else {
      setLoading(false);
      let clone = JSON.parse(JSON.stringify(contrat));
      setEditableContrat(clone);
      setEditableLicense(clone.license);
    }
  }, [contrat]);

  useEffect(() => {
    if (!agencyOptions)
      methodGet("/bonlocationapi/agency").then((res) => {
        setAgencyOptions(res);
        agencies = res;
      });
  }, [agencyOptions]);

  useEffect(() => {
    if (!licenseOptions)
      methodGet("/bonlocationapi/rentalagreements/license").then((res) => {
        res = [...Object.keys(res).map((e) => res[e])];
        setLicenseOptions(res);
        licenses = res;
      });
  }, [licenseOptions]);

  function modifEditContrat(e) {
    let newVal = { ...editableContrat };
    newVal[e.target.id] = e.target.value;
    setEditableContrat(newVal);
  }

  async function modificateContrat(e) {
    setLoading(true);
    await methodPut("/bonlocationapi/rentalagreements/" + contrat.id, editableContrat)
      .then((res) => {
        setLoading(false);
        setEditing(false);
        setEditingMaterials([...editingMaterials.filter((e) => e !== "adresse" && e !== "caution" && e !== "permis")]);
        setContrat(null);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  async function modificatePermisConduire() {
    setLoading(true);
    let newVal = { ...editableLicense };
    if (newVal.first_name === null || newVal.first_name === undefined) newVal.first_name = "";
    if (newVal.issued_on === null || newVal.issued_on === undefined) newVal.issued_on = "";
    if (newVal.first_name.split(" ").length > 1 && (!newVal.last_name || newVal.last_name === "")) {
      newVal.first_name = editableLicense.first_name.split(" ").length > 1 ? editableLicense.first_name.split(" ")[0] : editableLicense.first_name;
      newVal.last_name = editableLicense.first_name.split(" ").length > 1 ? editableLicense.first_name.split(" ")[1] : "";
    } else if (!newVal.last_name) newVal.last_name = "";
    if (newVal.issued_on === "") newVal.issued_on = moment().toDate().getTime() / 1000;
    if (newVal.number === null || newVal.number === undefined) newVal.number = "";
    if (contrat.license.id > 0) {
      await methodPut("/bonlocationapi/rentalagreements/license/" + contrat.license.id, newVal);
    } else {
      if (newVal.issued_on === "") newVal.issued_on = moment().toDate().getTime() / 1000;
      let id = await methodPost("/bonlocationapi/rentalagreements/license", newVal);
      newVal["id"] = id;
    }
    let newContrat = { ...editableContrat };
    newContrat.license_id = newVal.id;
    let updated = await methodPut("/bonlocationapi/rentalagreements/" + contrat.id, newContrat);
    setContrat(updated);
    setEditableContrat({ delivery_address: {} });
    setEditableLicense({});
    setLoading(false);
    setEditingMaterials([...editingMaterials.filter((e) => e !== "adresse" && e !== "caution" && e !== "permis")]);
    setEditing(false);
  }

  useEffect(() => {
    if (!contrat) return;
    if (contrat.status_id === 1 && contrat.products.find((e) => e.startReport !== null) !== undefined) onChangeStatut(contrat.status_id + 1);
    else if (contrat.status_id === 2 && contrat.products.find((e) => e.startReport === null) === undefined) onChangeStatut(contrat.status_id + 1);
    else if (contrat.status_id === 3 && contrat.products.find((e) => e.startReport === null) !== undefined) onChangeStatut(contrat.status_id - 1);
    else if (contrat.status_id === 3 && contrat.products.find((e) => e.returnReport !== null) !== undefined) onChangeStatut(contrat.status_id + 1);
    else if (contrat.status_id === 4 && contrat.products.find((e) => e.returnReport === null) === undefined) onChangeStatut(contrat.status_id + 1);
  }, [contrat]);

  async function onChangeStatut(newStatus) {
    setLoading(true);
    await methodPut("/bonlocationapi/rentalagreements/change_status/" + contrat.id + "/" + newStatus, {});
    if (contrat.status_id === 5) {
      let data = {
        socid: contrat.societe.id,
        statut: "1",
        array_options: { options_pv: contrat.agency.id },
        lines: [
          ...contrat.products.map((p) => {
            return {
              desc: "Location du " + moment(p.startReport.date * 1000).format("DD/MM/YYYY") + " au " + moment(p.returnReport.date * 1000).format("DD/MM/YYYY"),
              qty: moment(p.returnReport.date * 1000).diff(moment(p.startReport.date * 1000), "days"),
              subprice: p.rate,
              tva_tx: "20.000",
              fk_product: p.product_id,
              remise_percent: "0",
            };
          }),
        ],
        model_pdf: "crabe_arnal",
      };
      if (contrat.propal_id !== null)
        data.linkedObjectsIds = {
          propal: {
            1557: contrat.propal_id,
          },
        };
      await methodPost("/invoices", data);

      // Pour passer la proposition a statut facture
      await methodPost("/proposals/" + contrat.id + "/setinvoiced");
    }
    setContrat(null);
  }

  async function onCancelContrat() {
    setLoading(true);
    await methodPut("/bonlocationapi/rentalagreements/change_status/" + contrat.id + "/" + 7, {});
    setContrat(null);
  }

  return (
    <>
      <Structure title={"Contrat de location de materiel"}>
        {loading === "deleted" && (
          <div className="flex flex-col items-center justify-center space-y-4 mt-20">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
              <path
                fillRule="evenodd"
                d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
            <p>Contrat supprimé avec succès</p>
            <PrimaryButton text="Retour au tableau de bord" onClick={() => navigate("/")} />
          </div>
        )}
        {loading === true && <Spinner />}
        {!loading && contrat && (
          <div
            className={
              "relative max-w-7xl mx-auto rounded-lg m-5 mt-8 p-6 pt-10 space-y-8 " +
              (editing ? "bg-gray-50 dark:bg-gray-800 border border-dashed border-gray-500 " : "bg-white dark:bg-gray-900 shadow-lg")
            }
          >
            <div className="flex items-center justify-between">
              <div className="flex items-end space-x-2">
                <h2 className="font-bold text-3xl">
                  {contrat.societe.nom}{" "}
                  {!editing && (
                    <span className="font-normal text-sm">
                      {contrat.ref_order ? contrat.ref_order.replaceAll("\\", "/") : ""} | {contrat.ref ? contrat.ref.replaceAll("\\", "/") : ""}
                    </span>
                  )}
                </h2>
                {editing && (
                  <>
                    <input
                      id="ref_order"
                      className="relative block appearance-none w-36 rounded-md border dark:bg-gray-900 border-gray-300 dark:border-gray-700  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 px-3 py-0.5 sm:text-sm "
                      onChange={modifEditContrat}
                      defaultValue={contrat.ref_order}
                    />
                  </>
                )}
                <div className={"rounded-full px-4 py-1 text-center text-sm text-white font-semibold " + getContratStatut(contrat.status_id).color}>
                  {getContratStatut(contrat.status_id).name}
                </div>
              </div>
              <div className="flex space-x-2 items-center">
                {!editing && (
                  <>
                    <p>{moment(contrat.created_date * 1000).format("DD/MM/YYYY")}</p>
                    <p>|</p>
                    <p>{contrat.agency.name}</p>{" "}
                  </>
                )}
                {editing && (
                  <>
                    <input
                      id="created_date"
                      type={"date"}
                      className="relative block appearance-none w-36 rounded-md border dark:bg-gray-900 border-gray-300 dark:border-gray-700  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 px-3 py-0.5 sm:text-sm "
                      onChange={modifEditContrat}
                      defaultValue={moment(contrat.created_date * 1000).format("YYYY-MM-DD")}
                    />
                    <Select
                      options={
                        !agencyOptions
                          ? []
                          : [
                              ...agencyOptions.map((e) => {
                                return { value: e.id, label: e.name };
                              }),
                            ]
                      }
                      small={false}
                      placeholder="Agence . . ."
                      isClearable={false}
                      defaultValue={contrat.agency ? contrat.agency.id : null}
                      onChange={(e) =>
                        setEditableContrat({
                          ...editableContrat,
                          ["agency_id"]: e ? e.value : e,
                        })
                      }
                    />
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              <EditableValue
                title="Demandeur"
                small={false}
                editing={editing}
                value={contrat.applicant ? contrat.applicant : "Pas de Demandeur"}
                id="applicant"
                onChange={modifEditContrat}
              />
              <EditableValue
                title="Agent"
                editing={editing}
                small={false}
                placeholder="Nom Agent . . ."
                value={contrat.name_agent}
                id="name_agent"
                onChange={modifEditContrat}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <div className={"rounded-full flex items-center space-x-2 text-center font-semibold text-sm " + (editing ? "" : " border px-5 py-1")}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  <EditableValue
                    editing={editing}
                    small={false}
                    value={editableContrat.phone ? editableContrat.phone : contrat.phone ? contrat.phone : "Pas de telephone"}
                    defaultValue={contrat.phone ? contrat.phone : null}
                    id="phone"
                    onChange={modifEditContrat}
                  />
                </div>
                <div className={"rounded-full flex items-center space-x-2 text-center font-semibold text-sm " + (editing ? "" : " border px-5 py-1")}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                  <EditableValue
                    editing={editing}
                    small={false}
                    value={contrat.email ? contrat.email : "Pas de email"}
                    defaultValue={contrat.email ? contrat.email : null}
                    id="email"
                    onChange={modifEditContrat}
                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <p className="font-semibold text-sm">Renonciation à recours : </p>
                {!editing && (
                  <p>
                    {contrat.type_appeal === 1
                      ? "oui"
                      : contrat.type_appeal === 2
                      ? "non"
                      : contrat.type_appeal === 3
                      ? "10 %"
                      : contrat.type_appeal === 0
                      ? contrat.other_appeal
                      : "-"}
                  </p>
                )}
                {editing && editableContrat.type_appeal !== 0 && (
                  <Select
                    options={[
                      { value: 1, label: "oui" },
                      { value: 2, label: "non" },
                      { value: 3, label: "10 %" },
                      { value: 0, label: "autre" },
                    ]}
                    defaultValue={contrat.type_appeal}
                    isClearable={false}
                    small={false}
                    onChange={(e) => {
                      let newVal = { ...editableContrat };
                      newVal["type_appeal"] = e.value;
                      setEditableContrat(newVal);
                    }}
                  />
                )}
                {editing && editableContrat.type_appeal === 0 && (
                  <>
                    <EditableValue placeholder="autre . . ." editing={editing} value={contrat.other_appeal} id="other_appeal" onChange={modifEditContrat} />
                    <svg
                      onClick={() => {
                        let newVal = { ...editableContrat };
                        newVal["type_appeal"] = null;
                        setEditableContrat(newVal);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="cursor-pointer w-4 h-4"
                    >
                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                  </>
                )}
              </div>
            </div>
            <div className="absolute left-3 -top-12 w-full flex items-center justify-end space-x-2">
              <EditAndSaveButtons
                showDeleteButton={true}
                onDelete={() =>
                  askConfirmationModal(
                    "Êtes-vous sûr de vouloir supprimer le contrat de bon de location de matériel ?",
                    "Vous ne pourrez pas annuler cette action.",
                    () => {},
                    () => {
                      setLoading(true);
                      methodDelete("/bonlocationapi/rentalagreements/" + contrat.id).then(() => setLoading("deleted"));
                    }
                  )
                }
                isEditable={contrat.status_id === 1}
                editing={editing}
                setEditing={setEditing}
                onSave={modificateContrat}
              />
            </div>
            <div className="flex justify-between">
              <EditBox
                title="Permis de Conduire"
                onChange={(e) => {
                  if (e) setEditingMaterials([...editingMaterials, "permis"]);
                  else setEditingMaterials([...editingMaterials.filter((e) => e !== "permis")]);
                }}
                isEditable={contrat.status_id === 1}
                onSave={modificatePermisConduire}
              >
                {(editing) => (
                  <>
                    <div className="flex items-center justify-between space-x-2 h-6 text-sm">
                      <p className="font-semibold whitespace-nowrap">À :</p>
                      {!editing && (
                        <p className="whitespace-nowrap text-ellipsis">
                          {(contrat.license.first_name ? contrat.license.first_name : "") + " " + (contrat.license.last_name ? contrat.license.last_name : "")}
                        </p>
                      )}
                      {editing && (
                        <Select
                          placeholder="Nom . . ."
                          options={
                            licenseOptions
                              ? [
                                  ...licenseOptions.map((e) => {
                                    return {
                                      label: e.first_name + " " + e.last_name,
                                      value: e.id,
                                    };
                                  }),
                                ]
                              : []
                          }
                          isSearchable={true}
                          onChange={(value) => {
                            let option = null;
                            if (value !== null && licenseOptions) {
                              option = licenseOptions.find((e) => e.id == value.value);
                            }
                            document.getElementById("number").value = option ? option.number : "";
                            document.getElementById("issued_on").value = option ? moment(option.issued_on * 1000).format("YYYY-MM-DD") : "";
                            setEditableLicense(option ? option : {});
                          }}
                          defaultValue={contrat.license && licenseOptions !== null ? licenseOptions.find((e) => e.id === contrat.license.id) : null}
                          onChangeSearch={(e) => {
                            if (e !== "") {
                              setEditableLicense({
                                ...editableLicense,
                                first_name: e,
                              });
                            }
                          }}
                          small={true}
                        />
                      )}
                    </div>
                    <EditableValue
                      id="issued_on"
                      onChange={(e) => {
                        let newVal = { ...editableLicense };
                        newVal[e.target.id] = e.target.value;
                        setEditableLicense(newVal);
                      }}
                      title="Délivré le"
                      value={
                        contrat.license.issued_on === null || contrat.license.issued_on === ""
                          ? "--/--/----"
                          : moment(contrat.license.issued_on * 1000).format("DD/MM/YYYY")
                      }
                      editing={editing}
                      defaultValue={contrat.license.issued_on ? moment(contrat.license.issued_on * 1000).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                      type="date"
                    />
                    <EditableValue
                      id="number"
                      onChange={(e) => {
                        let newVal = { ...editableLicense };
                        newVal[e.target.id] = e.target.value;
                        setEditableLicense(newVal);
                      }}
                      title="N°"
                      type="number"
                      value={contrat.license.number ? contrat.license.number : null}
                      editing={editing}
                      placeholder="N° . . ."
                    />
                  </>
                )}
              </EditBox>
              <EditBox
                title="Caution"
                onChange={(e) => {
                  if (e) setEditingMaterials([...editingMaterials, "caution"]);
                  else setEditingMaterials([...editingMaterials.filter((e) => e !== "caution")]);
                }}
                isEditable={contrat.status_id === 1}
                onSave={modificateContrat}
              >
                {(editing) => (
                  <>
                    <div className="flex items-center my-1  justify-between h-6 text-sm">
                      <p className="font-semibold whitespace-nowrap">Mode de Règlement :</p>
                      {!editing && (
                        <p>{contrat.type_deposit === 1 ? "Par Chèque" : contrat.type_deposit === 2 ? "CB" : contrat.type_deposit === 3 ? "Espèces" : "-"}</p>
                      )}
                      {editing && (
                        <Select
                          options={[
                            { value: 1, label: "Par Chèque" },
                            { value: 2, label: "CB" },
                            { value: 3, label: "Espèces" },
                          ]}
                          defaultValue={contrat.type_deposit}
                          small={true}
                          isClearable={false}
                          onChange={(e) => {
                            let newVal = { ...editableContrat };
                            newVal["type_deposit"] = e.value;
                            setEditableContrat(newVal);
                          }}
                        />
                      )}
                    </div>
                    <EditableValue
                      id="amount_deposit"
                      onChange={modifEditContrat}
                      title="Montant"
                      type="number"
                      value={contrat.amount_deposit ? "€" + contrat.amount_deposit : 0}
                      defaultValue={contrat.amount_deposit}
                      editing={editing}
                    />
                  </>
                )}
              </EditBox>
              <EditBox
                title="Adresse de livraison"
                onChange={(e) => {
                  if (e) setEditingMaterials([...editingMaterials, "adresse"]);
                  else setEditingMaterials([...editingMaterials.filter((e) => e !== "adresse")]);
                }}
                isEditable={contrat.status_id === 1}
                onSave={modificateContrat}
              >
                {(editing) => (
                  <>
                    <EditableValue
                      id="address"
                      title="Rue"
                      autocomplete="street-address"
                      placeholder="Rue . . ."
                      value={uncodeStringSpecialChars(contrat.delivery_address.address)}
                      editing={editing}
                      onChange={(e) => {
                        let newVal = { ...editableContrat };
                        newVal["delivery_address"][e.target.id] = e.target.value;
                        setEditableContrat(newVal);
                      }}
                    />
                    <EditableValue
                      id="adresse2"
                      autocomplete="street-address"
                      title="Rue 2"
                      placeholder="Rue 2 . . ."
                      value={uncodeStringSpecialChars(contrat.delivery_address.adresse2)}
                      editing={editing}
                      onChange={(e) => {
                        let newVal = { ...editableContrat };
                        newVal["delivery_address"][e.target.id] = e.target.value;
                        setEditableContrat(newVal);
                      }}
                    />
                    <EditableValue
                      id="zip"
                      title="Code Postal"
                      autocomplete="postal-code"
                      placeholder="Code Postal . . ."
                      value={contrat.delivery_address.zip ? parseInt(contrat.delivery_address.zip) : ""}
                      editing={editing}
                      type="number"
                      onChange={(e) => {
                        let newVal = { ...editableContrat };
                        newVal["delivery_address"][e.target.id] = e.target.value;
                        setEditableContrat(newVal);
                      }}
                    />
                    <EditableValue
                      id="town"
                      autocomplete="address-level2"
                      title="Ville"
                      placeholder="Ville . . ."
                      value={uncodeStringSpecialChars(contrat.delivery_address.town)}
                      editing={editing}
                      onChange={(e) => {
                        let newVal = { ...editableContrat };
                        newVal["delivery_address"][e.target.id] = e.target.value;
                        setEditableContrat(newVal);
                      }}
                    />
                  </>
                )}
              </EditBox>
            </div>
            <hr className="mx-4" />
            <div className="flex flex-col space-y-6">
              {contrat.products.map((product, k) => {
                product.edit = editingMaterials.includes(product.id);
                return (
                  <MaterielCard
                    key={product.id + "_product"}
                    p={product}
                    showConstat={() => setShowInfoConstat(product)}
                    setEditingMaterial={(e) => {
                      if (e) setEditingMaterials([...editingMaterials, product.id]);
                      else setEditingMaterials([...editingMaterials.filter((e) => e !== product.id)]);
                    }}
                    isAnnule={contrat.status_id === 7}
                    canAddConstat={editingMaterials.length > 0}
                    onSave={(p) => {
                      setContrat({
                        ...contrat,
                        products: [
                          ...contrat.products.map((e) => {
                            if (e.id === p.id) return p;
                            else return e;
                          }),
                        ],
                      });
                    }}
                    onDelete={() => {
                      let editContrat = { ...contrat };
                      editContrat.products = contrat.products.filter((p) => p.id !== product.id);
                      setContrat(editContrat);
                    }}
                  />
                );
              })}
            </div>
            <div className="flex justify-end space-x-4">
              {contrat.status_id <= 3 && <PrimaryButtonOutlined text="Ajouter du matériel à louer" onClick={() => setShowMaterielModal(contrat.id)} />}
              {!editing && contrat.status_id === 1 && contrat.products.length > 0 && (
                <SuccesButton disabled={editingMaterials.length > 0} text="Ajouter Constat de Depart" onClick={() => setShowModalDepart(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-2">
                    <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
                    <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
                    <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                  </svg>
                </SuccesButton>
              )}
              {contrat.status_id === 3 && (
                <DeleteButton text="Ajouter Constat de Retour" onClick={() => setShowModalDepart(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ scale: "-1 1" }} viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-2">
                    <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
                    <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
                    <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                  </svg>
                </DeleteButton>
              )}
              {contrat.status_id === 5 && (
                <PrimaryButton text="À facturer" onClick={() => onChangeStatut(contrat.status_id + 1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                    />
                  </svg>
                </PrimaryButton>
              )}
            </div>
            <hr className="mx-4" />
            <div className="w-2/4 mx-auto">
              <EditBox title="Observation" isEditable={contrat.status_id === 1} onSave={modificateContrat}>
                {(editing) =>
                  editing ? (
                    <textarea
                      id="comment"
                      onChange={modifEditContrat}
                      className="w-full border border-cyan-600 border-dashed bg-gray-50 dark:bg-gray-800 p-3 rounded-lg text-sm h-32"
                      defaultValue={contrat.comment}
                    />
                  ) : (
                    <div className="w-full border border-gray-900 dark:border-gray-200 p-3 overflow-auto rounded-lg text-sm h-32">
                      {contrat.comment && contrat.comment.split("\n").map((e) => <p>{e}</p>)}
                    </div>
                  )
                }
              </EditBox>
            </div>
            <div className="flex items-center justify-end space-x-5">
              {contrat.status_id < 6 && (
                <SecondaryOutlineButton
                  onClick={() => {
                    askConfirmationModal(
                      "Êtes-vous sûr de vouloir abandonner le contrat de bon de location de matériel ?",
                      "Vous pourrez le retrouver dans l'historique pour révision.",
                      () => {},
                      onCancelContrat
                    );
                  }}
                >
                  Abandonner le contrat
                </SecondaryOutlineButton>
              )}
              {contrat.status_id === 7 && (
                <DeleteButton
                  onClick={() => {
                    askConfirmationModal(
                      "Êtes-vous sûr de vouloir supprimer le contrat de bon de location de matériel ?",
                      "Vous ne pourrez pas annuler cette action.",
                      () => {},
                      () => {
                        setLoading(true);
                        methodDelete("/bonlocationapi/rentalagreements/" + contrat.id).then(() => setLoading("deleted"));
                      }
                    );
                  }}
                >
                  Supprimer le contrat
                </DeleteButton>
              )}
              <div className="flex flex-grow justify-end items-center mr-5 space-x-2">
                <label className="text-sm">générer pdf avec les prix</label>
                <input
                  id="generer_prix"
                  type="checkbox"
                  defaultChecked={false}
                  className="rounded dark:bg-gray-900 border border-p-purple dark:border-p-purple"
                />
              </div>
              <PrimaryButtonOutlined
                text="Générer PDF"
                onClick={() => {
                  setLoading(true);
                  methodGet("/bonlocationapi/export/" + contrat.id + (document.getElementById("generer_prix").checked ? "/1" : "/0")).then((res) => {
                    methodGet("/documents/download?modulepart=bonlocation&original_file=" + res).then((res2) => {
                      let a = document.createElement("a");
                      a.download = res2.filename;
                      a.href = `data:application/pdf;base64,${res2.content}`;
                      a.click();
                      a.remove();
                      setLoading(false);
                    });
                  });
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-6 h-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </PrimaryButtonOutlined>
            </div>
          </div>
        )}
      </Structure>
      {showModalDepart && <MultiConstatModal contrat={contrat} open={showModalDepart} setOpen={setShowModalDepart} onAdded={(p) => setContrat(null)} />}
      {showInfoConstat && <ConstatModal p={showInfoConstat} open={true} setOpen={(e) => setShowInfoConstat(null)} onAdded={(p) => setContrat(null)} />}
      {showMaterielModal && (
        <AddMaterielModal
          id={showMaterielModal}
          open={showMaterielModal !== null}
          setOpen={(e) => (e ? () => {} : setShowMaterielModal(null))}
          onAdded={async (product) => {
            let editContrat = { ...contrat };
            editContrat.products.push(product);
            // uncomment below to set editing element as default
            // setEditingMaterials([...editingMaterials, product.id]);
            setContrat(editContrat);
          }}
        />
      )}
    </>
  );
}

export function getContratStatut(id) {
  if (id === 1) {
    return {
      id: 1,
      name: "Nouveau",
      color: "bg-cyan-500",
    };
  } else if (id === 2) {
    return {
      id: 2,
      name: "p. Parti",
      color: "bg-green-600",
    };
  } else if (id === 3) {
    return {
      id: 3,
      name: "Parti",
      color: "bg-green-600",
    };
  } else if (id === 4) {
    return {
      id: 4,
      name: "p. Revenu",
      color: "bg-p-red",
    };
  } else if (id === 5) {
    return {
      id: 5,
      name: "Revenu",
      color: "bg-p-red",
    };
  } else if (id === 6) {
    return {
      id: 6,
      name: "Facturé",
      color: "bg-p-purple",
    };
  } else if (id === 7) {
    return {
      id: 7,
      name: "Abandonné",
      color: "bg-gray-500",
    };
  }
}
