import moment from "moment/moment";
import { useState } from "react";
import EditableValue from "../UI/EditableValue";
import Select from "../UI/Select";

export default function ConstatForm({ product, constat, setConstat, show, setShow, isMulti = false }) {
  function modificateProduct(e) {
    setConstat({ ...constat, [e.target.id]: e.target.value });
  }

  return (
    <>
      <div className="flex space-x-0 items-center w-full border-b-2">
        <p
          onClick={() => setShow("startReport")}
          className={
            "font-semibold flex items-center px-4 py-1.5 rounded-tl cursor-pointer " +
            (show === "startReport" ? "bg-green-500 text-white" : "border-t border-l text-gray-500")
          }
        >
          Constat de Depart
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-2">
            <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
            <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
            <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
          </svg>
        </p>
        {product.startReport && (
          <p
            onClick={() => setShow("returnReport")}
            className={
              "font-semibold flex items-center px-4 py-1.5 rounded-tr cursor-pointer " +
              (show === "returnReport" ? "bg-p-red text-white" : "border-t border-r text-gray-500")
            }
          >
            Constat de Retour
            <svg xmlns="http://www.w3.org/2000/svg" style={{ scale: "-1 1" }} viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 ml-2">
              <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
              <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
              <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
            </svg>
          </p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-x-20 gap-y-5 p-4">
        <div className={"flex items-center space-x-2 h-8 text-base"}>
          <p className="font-semibold w-full whitespace-nowrap">Propreté extérieure :</p>
          {product[show] && <p>{product[show].exterior_cleanliness}</p>}
          {!product[show] && (
            <Select
              defaultValue={{ value: constat.exterior_cleanliness }}
              key={"exterior_cleanliness"}
              id={"exterior_cleanliness"}
              options={[
                { value: "BON", label: "BON" },
                { value: "MOYEN", label: "MOYEN" },
                { value: "MAUVAIS", label: "MAUVAIS" },
              ]}
              onChange={(e) => setConstat({ ...constat, exterior_cleanliness: e ? e.value : "" })}
              small={false}
            />
          )}
        </div>
        <div className={"flex items-center space-x-2 h-8 text-base"}>
          <p className="font-semibold w-full whitespace-nowrap">Propreté intérieure :</p>
          {product[show] && <p>{product[show].interior_cleanliness}</p>}
          {!product[show] && (
            <Select
              defaultValue={{ value: constat.interior_cleanliness }}
              key={"interior_cleanliness"}
              id={"interior_cleanliness"}
              options={[
                { value: "BON", label: "BON" },
                { value: "MOYEN", label: "MOYEN" },
                { value: "MAUVAIS", label: "MAUVAIS" },
              ]}
              onChange={(e) => setConstat({ ...constat, interior_cleanliness: e ? e.value : "" })}
              small={false}
            />
          )}
        </div>
        <div className={"flex items-center space-x-2 h-8 text-base"}>
          <p className="font-semibold w-full whitespace-nowrap">Carrosserie :</p>
          {product[show] && <p>{product[show].body}</p>}
          {!product[show] && (
            <Select
              defaultValue={{ value: constat.body }}
              key={"body"}
              id={"body"}
              options={[
                { value: "BON", label: "BON" },
                { value: "MOYEN", label: "MOYEN" },
                { value: "MAUVAIS", label: "MAUVAIS" },
              ]}
              onChange={(e) => setConstat({ ...constat, body: e ? e.value : "" })}
              small={false}
            />
          )}
        </div>
        <div className={"flex items-center space-x-2 h-8 text-base"}>
          <p className="font-semibold w-full whitespace-nowrap">Feux, vitres, glaces :</p>
          {product[show] && <p>{product[show].lights_windows_mirrors}</p>}
          {!product[show] && (
            <Select
              defaultValue={{ value: constat.lights_windows_mirrors }}
              key={"lights_windows_mirrors"}
              id={"lights_windows_mirrors"}
              options={[
                { value: "BON", label: "BON" },
                { value: "MOYEN", label: "MOYEN" },
                { value: "MAUVAIS", label: "MAUVAIS" },
              ]}
              onChange={(e) => setConstat({ ...constat, lights_windows_mirrors: e ? e.value : "" })}
              small={false}
            />
          )}
        </div>
        <EditableValue
          editing={!product[show]}
          value={!product[show] ? constat.document : product[show].document}
          key={"document"}
          id={"document"}
          title="Documents"
          onChange={modificateProduct}
          small={false}
        />
        <EditableValue
          editing={!product[show]}
          value={!product[show] ? constat.fuel : product[show].fuel}
          key={"fuel"}
          id={"fuel"}
          title="Carburant"
          onChange={modificateProduct}
          small={false}
        />
        <div className="row-span-3">
          <p className="font-semibold w-full whitespace-nowrap">Commentaire :</p>
          {!product[show] ? (
            <textarea
              id="comment"
              onChange={modificateProduct}
              className="w-full border border-cyan-600 border-dashed bg-gray-50 dark:bg-gray-800 p-3 rounded-lg text-sm h-32"
              defaultValue={product.comment}
            />
          ) : (
            <div className="w-full border border-gray-900 dark:border-gray-200 p-3 overflow-auto rounded-lg text-sm h-32">
              {product[show].comment && product[show].comment.split("\n").map((e) => <p>{e}</p>)}
            </div>
          )}
        </div>
        <EditableValue
          editing={!product[show]}
          value={!product[show] ? constat.hours : product[show].hours}
          key={"hours"}
          id={"hours"}
          title="Heures véhicule"
          onChange={modificateProduct}
          small={false}
        />
        <EditableValue
          editing={!product[show]}
          value={!product[show] ? constat.mileage : product[show].mileage}
          key={"mileage"}
          id={"mileage"}
          title="Kms véhicule"
          onChange={modificateProduct}
          small={false}
        />
        {!isMulti && (
          <EditableValue
            id={"date_constat"}
            title="Date"
            type="datetime-local"
            editing={product[show] === null}
            value={
              !product[show]
                ? product[show === "startReport" ? "release_date" : "return_date"] !== ""
                  ? moment(product[show === "startReport" ? "release_date" : "return_date"] * 1000).format("YYYY-MM-DD HH:mm")
                  : null
                : moment(product[show].date * 1000).format("YYYY-MM-DD HH:mm")
            }
            small={false}
          />
        )}
      </div>
    </>
  );
}
