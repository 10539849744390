export default function SearchBar({ id, placeholder = "Rechercher...", onChange }) {
  return (
    <div className="flex flex-grow max-w-xs ml-auto">
      <input
        id={id}
        type="text"
        className="relative block w-full appearance-none rounded-l-md border bg-gray-50 dark:bg-gray-800 border-gray-300 dark:border-gray-700  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 px-4 py-2 sm:text-sm"
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <div className="w-20 rounded-r-md bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 flex items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>
      </div>
    </div>
  );
}
