import { useState } from "react";

export default function Switch({ text, defaultChecked = false, onChange }) {
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <div className="flex space-x-2 m-5">
      <div
        onClick={() => {
          onChange(!checked);
          setChecked(!checked);
        }}
        className={
          "relative transition-all duration-300 ease-in-out cursor-pointer items-center w-10 rounded-full " + (checked ? "bg-blue-500" : "bg-gray-500")
        }
      >
        <div className={"absolute transition-all duration-300 ease-in-out w-6 h-6 bg-white rounded-full " + (checked ? "right-0" : "right-4")} />
      </div>
      <label className="font-medium">{text}</label>
    </div>
  );
}
