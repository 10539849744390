import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SpinnerSmall } from "./Spinner";
import { getHighlightedText } from "../../providers/Functions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Select({
  options,
  onChange = null,
  onChangeSearch = null,
  defaultValue = null,
  isClearable = true,
  isSearchable = false,
  expandOnSearch = false,
  small = false,
  placeholder = "Select . . .",
  id = "select",
}) {
  const [selected, setSelected] = useState(defaultValue === null ? null : options.find((e) => e.value == defaultValue.value));
  const [firstRender, setFirstRender] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (firstRender) setFirstRender(false);
    else if (onChange) onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (onChangeSearch) onChangeSearch(search);
    if (options) {
      let filtered = options.filter((e) => e.label.toLowerCase().includes(search.toLowerCase()));
      setFilteredOptions(filtered);
    }
  }, [search]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative max-w-md min-w-fit">
            <Listbox.Button
              id={id}
              className={
                (isSearchable && !selected ? "" : small ? "py-0.5 md:py-.5 pl-2" : "py-1 md:py-2 pl-3") +
                " pr-8 relative flex items-center w-full cursor-default rounded-md border border-gray-300 dark:border-gray-600 bg-transparent text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-xs md:text-sm"
              }
            >
              {isSearchable && !selected && (
                <input
                  onClick={(e) => e.preventDefault()}
                  key="input-search_select"
                  className={
                    "relative block w-full appearance-none rounded-l-md border bg-gray-50 dark:bg-gray-800 border-gray-300 dark:border-gray-700  focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 " +
                    (small ? "px-2 py-1 text-xs" : "px-4 py-2 text-sm")
                  }
                  placeholder={placeholder}
                  type="text"
                  autoComplete="newPassword"
                  onKeyDown={(e) => {
                    if (e.code === "Space") {
                      e.preventDefault();
                      setSearch(search + " ");
                    }
                  }}
                  onChange={(event) => setSearch(event.target.value)}
                  value={search}
                />
              )}
              <span className="flex items-center">
                {selected && <span className="ml-1 mr-4 md:ml-3 block truncate font-medium">{selected.label}</span>}
                {!selected && !isSearchable && <span className="text-gray-500 dark:text-gray-300 font-light mr-auto">{placeholder}</span>}
                {!options && <SpinnerSmall />}
              </span>
              {selected && isClearable && (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setSelected(null);
                  }}
                  className="cursor-pointer absolute inset-y-0 right-6 ml-3 flex items-center pr-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </span>
              )}
              <span className="cursor-pointer absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </span>
            </Listbox.Button>
            {(!expandOnSearch || search.length >= 3) && (
              <Transition
                show={(open || (isSearchable && search !== "" && filteredOptions.length > 0 && selected === null)) && options !== null}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 min-w-24 max-w-xs overflow-auto rounded-md bg-white dark:bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredOptions &&
                    filteredOptions.map((option, k) => (
                      <Listbox.Option
                        key={option.label + "-option-" + k}
                        className={({ active }) =>
                          classNames(
                            active ? "" : "",
                            "relative cursor-default hover:text-white hover:bg-indigo-300 hover:dark:bg-indigo-700 flex items-center justify-between select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={
                                (small ? "text-xs " : "text-xs md:text-sm ") + classNames(selected ? "font-bold" : "font-normal", "ml-3 block truncate")
                              }
                            >
                              {isSearchable ? getHighlightedText(option.label, search) : option.label}
                            </span>
                            {selected && (
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                <path
                                  fillRule="evenodd"
                                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
}
