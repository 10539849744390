import moment from "moment";
import { useEffect, useState } from "react";
import { methodDelete, methodPut } from "../../providers/ApiProvider";
import { DeleteButton } from "../UI/Buttons";
import EditableValue from "../UI/EditableValue";
import { Spinner } from "../UI/Spinner";
import EditAndSaveButtons from "./Edit&SaveButtons";

export default function MaterielCard({ p, showConstat, isAnnule, onDelete, setEditingMaterial, canAddConstat = true, onSave }) {
  const [product, setProduct] = useState(p);
  const [editing, setEditing] = useState(p.edit ? p.edit : false);
  const [loading, setloading] = useState(false);

  async function handleSaveProduct() {
    setloading(true);
    await methodPut("/bonlocationapi/product/" + product.id, product);
    setloading(false);
    setEditingMaterial(false);
    setEditing(false);
    onSave(product);
  }

  function dateSortieIsSetted() {
    return product.release_date && product.release_date !== "";
  }

  function dateRetourIsSetted() {
    return product.return_date && product.return_date !== "";
  }

  return (
    <div className={"relative max-w-5xl w-full mx-auto px-8 py-6 rounded-xl border border-gray-900 dark:border-gray-200 " + (editing ? "border-dashed" : "")}>
      <div className="absolute left-1 -bottom-4 flex w-full justify-end items-center space-x-2">
        {!editing && !isAnnule && (
          <button
            type="button"
            disabled={canAddConstat}
            className={
              "rounded-full text-white  shadow-sm focus:outline-none p-2 " +
              (product.startReport === null
                ? "hover:bg-green-500 bg-green-600 dark:hover:bg-green-700 disabled:bg-opacity-40 "
                : product.returnReport === null
                ? "bg-p-red hover:bg-red-700"
                : "bg-gray-600 hover:bg-gray-700")
            }
            onClick={showConstat}
          >
            {(product.startReport === null || product.returnReport === null) && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ scale: product.startReport === null ? "1 1" : "-1 1" }}
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.5 3c-1.051 0-2.093.04-3.125.117A1.49 1.49 0 002 4.607V10.5h9V4.606c0-.771-.59-1.43-1.375-1.489A41.568 41.568 0 006.5 3zM2 12v2.5A1.5 1.5 0 003.5 16h.041a3 3 0 015.918 0h.791a.75.75 0 00.75-.75V12H2z" />
                <path d="M6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM13.25 5a.75.75 0 00-.75.75v8.514a3.001 3.001 0 014.893 1.44c.37-.275.61-.719.595-1.227a24.905 24.905 0 00-1.784-8.549A1.486 1.486 0 0014.823 5H13.25zM14.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
              </svg>
            )}
            {product.startReport !== null && product.returnReport !== null && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        )}
        <EditAndSaveButtons
          isEditable={!isAnnule && product.returnReport === null}
          setEditing={(e) => {
            setEditing(e);
            if (!e) setProduct(p);
            setEditingMaterial(e);
          }}
          editing={editing}
          showDeleteButton={product.startReport === null}
          onDelete={async () => {
            try {
              setloading(true);
              await methodDelete("/bonlocationapi/rentalagreements/product/" + product.id);
              setEditingMaterial(false);
              onDelete();
            } catch (error) {
              window.location.reload();
            }
          }}
          onSave={() => handleSaveProduct()}
          small={true}
        />
      </div>
      <div className="flex justify-between items-center">
        <p className="text-xl font-bold">
          {product.name} <span className="text-base font-normal">{product.reference}</span>
        </p>
        {(product.rate || product.total_rate) && !editing && (
          <p className="text-xl font-bold">
            <span className="text-base font-normal">€{Math.round(product.rate * 100) / 100}/jour</span> €
            {Math.round((product.total_rate === null ? product.rate : product.total_rate) * 100) / 100} Total{" "}
          </p>
        )}
      </div>
      <div className={editing ? "grid grid-cols-2 gap-x-20 gap-y-2 p-5" : "flex space-x-5 mt-4"}>
        {editing && (
          <>
            <EditableValue
              id={"rate-" + product.id}
              title="Prix journalier"
              type="number"
              editing={editing && product.startReport === null}
              value={Math.round(product.rate * 100) / 100}
              onChange={(e) => {
                document.getElementById("total_rate-" + product.id).value =
                  Math.round(e.target.value * (moment(product.return_date * 1000).diff(moment(product.release_date * 1000), "day") + 1) * 100) / 100;
                setProduct({ ...product, rate: e.target.value, total_rate: document.getElementById("total_rate-" + product.id).value });
              }}
            />
            <EditableValue
              id={"total_rate-" + product.id}
              title="Prix total"
              type="number"
              editing={editing && product.startReport === null}
              value={Math.round((product.total_rate === null ? product.rate : product.total_rate) * 100) / 100}
              onChange={(e) => {
                setProduct({ ...product, total_rate: e.target.value });
              }}
            />
          </>
        )}
        {(dateSortieIsSetted() || editing) && (
          <EditableValue
            id="release_date"
            title={product.startReport === null ? "Sortie Prévue" : "Sortie"}
            type="datetime-local"
            editing={editing}
            value={product.release_date ? moment(product.release_date * 1000).format(editing ? "YYYY-MM-DD HH:mm" : "DD/MM/YYYY HH:mm") : null}
            onChange={(e) => {
              if (dateRetourIsSetted() && e.target.value != "" && document.getElementById("total_rate-" + product.id))
                document.getElementById("total_rate-" + product.id).value =
                  Math.round(product.rate * (moment(product.return_date * 1000).diff(moment(e.target.value), "day") + 1) * 100) / 100;
              setProduct({
                ...product,
                release_date: Math.round(moment(e.target.value).toDate().getTime() / 1000),
                total_rate: Math.round(product.rate * (moment(product.return_date * 1000).diff(moment(e.target.value), "day") + 1) * 100) / 100,
              });
            }}
          />
        )}
        {!dateSortieIsSetted() && !editing && (
          <div className="flex items-center space-x-2 text-base">
            <p className="font-semibold whitespace-nowrap">Sortie Prévue : </p>

            <p className="whitespace-nowrap flex items-center text-orange-700 dark:text-orange-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
              Date à saisir
            </p>
          </div>
        )}

        {(dateRetourIsSetted() || editing) && (
          <EditableValue
            id="return_date"
            title={product.returnReport === null ? "Retour Prévue" : "Retour"}
            type="datetime-local"
            editing={editing}
            value={product.return_date ? moment(product.return_date * 1000).format(editing ? "YYYY-MM-DD HH:mm" : "DD/MM/YYYY HH:mm") : null}
            onChange={(e) => {
              if (dateSortieIsSetted() && e.target.value !== "" && document.getElementById("total_rate-" + product.id))
                document.getElementById("total_rate-" + product.id).value =
                  Math.round(product.rate * (moment(e.target.value).diff(moment(product.release_date * 1000), "day") + 1) * 100) / 100;

              setProduct({
                ...product,
                return_date: Math.round(moment(e.target.value).toDate().getTime() / 1000),
                total_rate: Math.round(product.rate * (moment(e.target.value).diff(moment(product.release_date * 1000), "day") + 1) * 100) / 100,
              });
            }}
          />
        )}
        {!dateRetourIsSetted() && !editing && (
          <div className="flex items-center space-x-2 text-base">
            <p className="font-semibold whitespace-nowrap">Retour Prévue : </p>

            <p className="whitespace-nowrap flex items-center text-orange-700 dark:text-orange-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
              Date à saisir
            </p>
          </div>
        )}
        <EditableValue
          id={"hours-" + product.id}
          title="Heures"
          type="number"
          editing={editing && product.startReport === null}
          value={Math.round((product.hours ? product.hours : 0) * 100) / 100}
          onChange={(e) => {
            setProduct({ ...product, hours: e.target.value });
          }}
        />
        <EditableValue
          id={"mileage-" + product.id}
          title="Kms véhicule"
          type="number"
          editing={editing && product.startReport === null}
          value={Math.round((product.mileage ? product.mileage : 0) * 100) / 100}
          onChange={(e) => {
            setProduct({ ...product, mileage: e.target.value });
          }}
        />
        {editing && product.startReport === null && (
          <textarea
            id="comment"
            onChange={(e) =>
              setProduct({
                ...product,
                comment: e.target.value,
              })
            }
            className="w-full border col-span-2 border-cyan-600 border-dashed bg-gray-50 dark:bg-gray-800 p-3 rounded-lg text-sm h-32"
            defaultValue={product.comment}
          />
        )}
      </div>
      {(!editing || product.startReport !== null) && (
        <div className="w-full mt-3 dark:bg-gray-800 bg-gray-100 p-3 overflow-auto rounded-lg text-sm h-28">
          {product.comment && product.comment.split("\n").map((e) => <p>{e}</p>)}
        </div>
      )}
      {loading && <Spinner />}
    </div>
  );
}
