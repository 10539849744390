import Structure from "../components/navigation/Structure";
import Switch from "../components/UI/Switch";

export default function Settings() {
  return (
    <Structure title={"Paramètres"}>
      <Switch
        text="Mode sombre"
        defaultChecked={localStorage.getItem("theme") === "dark"}
        onChange={(e) => {
          const theme = e ? "dark" : "ligth";
          localStorage.setItem("theme", theme);
          document.body.classList = theme === "dark" ? "dark bg-gray-800 text-gray-100" : "bg-gray-50 text-gray-600";
          document.body.style = "color-scheme: " + theme + ";";
          window.location.reload();
        }}
      />
    </Structure>
  );
}
