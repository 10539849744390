import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { methodGet, methodPost } from "../../providers/ApiProvider";
import { SecondaryOutlineButton } from "../UI/Buttons";
import EditableValue from "../UI/EditableValue";
import Modal from "../UI/Modal";
import Select from "../UI/Select";

let agencies = null;

export default function ModalCreateBL({ open, setOpen, duplicate = null }) {
  const navigate = useNavigate();
  const [clientsOptions, setClientsOptions] = useState(null);
  const [clientSelected, setClientSelected] = useState(null);
  const [createBL, setCreateBL] = useState(null);
  const [agencyOptions, setAgencyOptions] = useState(agencies);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setloading] = useState(true);
  const [bonsOptions, setBonsOptions] = useState(null);

  useEffect(() => {
    if (!clientsOptions) {
      setloading(true);
      methodGet("/bonlocationapi/thirdparties/get_all").then(async (res) => {
        if (duplicate !== null) {
          let res2 = await methodGet("/bonlocationapi/rentalagreements/" + duplicate);
          let selectClient = res.find((e) => parseInt(e.id) == res2.societe.id);
          if (selectClient !== undefined)
            setClientSelected({ value: selectClient.id, label: selectClient.name + (selectClient.town ? " - " + selectClient.town : "") });
          setCreateBL({
            duplicate: duplicate,
            agency: res2.agency ? { value: res2.agency.id } : null,
            agency_id: res2.agency ? res2.agency.id : null,
            ref_order: res2.ref_order,
            applicant: res2.applicant,
            name_agent: res2.name_agent,
            email: res2.email,
            phone: res2.phone,
          });
        }
        setClientsOptions([
          ...res.map((e) => {
            return { ...e, value: e.id, label: e.name + " - " + e.town };
          }),
        ]);
      });
    } else {
      setloading(false);
    }
  }, [clientsOptions]);

  useEffect(() => {
    if (!agencyOptions)
      methodGet("/bonlocationapi/agency").then((res) => {
        setAgencyOptions(res);
        agencies = res;
      });
  }, [agencyOptions]);

  useEffect(() => {
    if (errorMessage)
      setTimeout(() => {
        setErrorMessage(null);
      }, 7000);
  }, [errorMessage]);

  function changeCreateBL(e) {
    let newVal = { ...createBL };
    newVal[e.target.id] = e.target.value;
    setCreateBL(newVal);
  }

  useEffect(() => {
    if (bonsOptions === null && clientSelected) {
      handleImportDolibarr();
    }
    if (clientSelected === null && !duplicate) {
      setCreateBL(null);
      setBonsOptions(null);
    }
  }, [clientSelected]);

  function handleImportDolibarr() {
    if (clientSelected === null) return setErrorMessage("Veuillez sélectionner un client.");
    setloading(true);
    methodGet("/proposals?sortfield=t.rowid&sortorder=ASC&limit=100&sqlfilters=(t.fk_soc=" + clientSelected.value + ")and(t.fk_statut=2)")
      .then((res) => {
        setBonsOptions(res);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  async function createImportDolibarr(bon) {
    setloading(true);
    methodPost("/bonlocationapi/rentalagreements/" + bon.id, {})
      .then((id) => {
        setloading(false);
        navigate("/contrat/" + id);
      })
      .catch((err) => {
        setErrorMessage(err.error.message);
        return setloading(false);
      });
  }

  async function handleCreerBL() {
    if (clientSelected === null) return setErrorMessage("Veuillez sélectionner un client.");
    if (createBL.agency_id === null || createBL.agency_id === undefined) return setErrorMessage("Veuillez sélectionner une agence.");
    if (createBL.name_agent === null || createBL.name_agent === undefined) return setErrorMessage("Veuillez saisir le nom de l'Agent.");
    setloading(true);
    methodPost("/bonlocationapi/rentalagreements", { societe_id: clientSelected.value, ...createBL })
      .then((id) => {
        setloading(false);
        navigate("/contrat/" + id);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setloading(false);
      });
  }

  return (
    <Modal
      title={(duplicate ? "Dupliquer" : "Créer") + " un bon de location"}
      open={open}
      loading={loading}
      setOpen={setOpen}
      onActtionButton={handleCreerBL}
      showActionButton={createBL !== null}
      errorMessgae={errorMessage}
    >
      {clientsOptions && (
        <Select
          options={clientsOptions}
          expandOnSearch={true}
          isSearchable={true}
          defaultValue={clientSelected}
          placeholder="Sélectionner Client . . ."
          onChange={(e) => setClientSelected(e)}
        />
      )}
      {!clientSelected && <div className="h-64" />}
      {createBL && clientSelected && (
        <div className="grid grid-cols-2 col-span-5 gap-x-24 gap-y-4 p-10">
          <EditableValue
            id="ref_order"
            title="Réf. commande"
            placeholder="Réference..."
            small={false}
            onChange={changeCreateBL}
            editing={true}
            defaultValue={createBL.ref_order}
          />
          <div className="flex items-center justify-betweenh-10 text-base">
            <p className="font-semibold whitespace-nowrap mr-auto">Agence :</p>
            <Select
              options={
                !agencyOptions
                  ? []
                  : [
                      ...agencyOptions.map((e) => {
                        return { value: e.id, label: e.name };
                      }),
                    ]
              }
              defaultValue={createBL.agency}
              placeholder="Selectioner Agence . . ."
              onChange={(e) => {
                let newVal = { ...createBL };
                newVal["agency_id"] = e ? e.value : null;
                setCreateBL(newVal);
              }}
            />
          </div>
          <EditableValue
            id="applicant"
            title="Demandeur"
            placeholder="demandeur..."
            small={false}
            onChange={changeCreateBL}
            editing={true}
            defaultValue={createBL.applicant}
          />
          <EditableValue
            id="name_agent"
            title="Nom Agent"
            placeholder="nom de l'agent..."
            small={false}
            onChange={changeCreateBL}
            editing={true}
            defaultValue={createBL.name_agent}
          />
          <EditableValue
            id="email"
            title="Email"
            placeholder="email..."
            small={false}
            type="email"
            onChange={changeCreateBL}
            editing={true}
            defaultValue={clientSelected.email}
          />
          <EditableValue
            id="phone"
            title="Téléphone"
            placeholder="téléphone..."
            small={false}
            type="tel"
            onChange={changeCreateBL}
            editing={true}
            defaultValue={clientSelected.phone}
          />
        </div>
      )}
      {!createBL && clientSelected && (
        <div className="flex flex-col p-10 space-y-2 max-h-96 overflow-y-auto">
          {bonsOptions &&
            bonsOptions.map((bon) => (
              <div className="px-6 py-4 flex items-center justify-between w-full border border-gray-800 dark:border-gray-200 rounded-lg">
                <h2 className="font-bold sm:text-xl">
                  {bon.ref}
                  <span className="font-normal text-sm ml-2">{bon.lines.length} articles à louer</span>
                </h2>
                <div className="flex items-center space-x-4">
                  <h2 className="font-bold sm:text-xl">€ {Math.round(bon.total_ht)}</h2>
                  <SecondaryOutlineButton
                    text={"Importer"}
                    onClick={() => {
                      createImportDolibarr(bon);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                      />
                    </svg>
                  </SecondaryOutlineButton>
                </div>
              </div>
            ))}
          <div
            onClick={() => setCreateBL({ email: clientSelected.email, phone: clientSelected.phone })}
            className="p-6 cursor-pointer rounded-xl border border-dashed hover:bg-indigo-300 dark:hover:bg-indigo-900 hover:bg-opacity-10 border-p-purple dark:border-pd-purple"
          >
            <p className="mx-auto whitespace-nowrap w-min font-semibold text-p-purple dark:text-pd-purple">+ Creer Nouveau Bon de Location</p>
          </div>
        </div>
      )}
    </Modal>
  );
}
