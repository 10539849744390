// surligne le texte pendant une recherche
export function getHighlightedText(text, highlight) {
  if (text === null) return "";
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return highlight === null || highlight === undefined || highlight === "" ? (
    text
  ) : (
    <span data-tag="allowRowEvents">
      {parts.map((part, i) => (
        <span
          data-tag="allowRowEvents"
          key={i}
          className="text-gray-900 dark:text-gray-50"
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold", backgroundColor: localStorage.getItem("theme") === "dark" ? "#064E38" : "#D1FAE5" }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
}

export function uncodeStringSpecialChars(str) {
  if (str === undefined || str === null) return "";
  return str.replaceAll(" u00e9", "é").replaceAll(" u00e8", "è").replaceAll(" u00e7", "ç").replaceAll(" u00e0", "à").replaceAll(" u00f9", "ù");
}
