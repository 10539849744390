const url = process.env.REACT_APP_IS_PROD === 1 ? process.env.REACT_APP_DOLIBARR_URL : process.env.REACT_APP_DOLIBARR_URL_TEST;

export function methodGet(path) {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    let myHeaders = new Headers();
    let myInit = {
      headers: myHeaders,
      signal: controller.signal,
      method: "GET",
    };
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("DOLAPIKEY", localStorage.getItem("DOLAPIKEY"));

    fetch(url + path, myInit)
      .then((res) => {
        if (!res.ok)
          return res.json().then((err) => {
            throw err;
          });
        else return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
  });
}

export function methodPost(path, params) {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    let myHeaders = new Headers();
    let myInit = {
      headers: myHeaders,
      signal: controller.signal,
      method: "POST",
      body: JSON.stringify(params),
    };
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("DOLAPIKEY", localStorage.getItem("DOLAPIKEY"));

    fetch(url + path, myInit)
      .then((res) => {
        if (!res.ok)
          return res.json().then((err) => {
            throw err;
          });
        else return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          alert(error.error.message);
          reject(error);
        }
      );
  });
}

export function methodPut(path, params) {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    let myHeaders = new Headers();
    let myInit = {
      headers: myHeaders,
      signal: controller.signal,
      method: "PUT",
      body: JSON.stringify(params),
    };
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("DOLAPIKEY", localStorage.getItem("DOLAPIKEY"));

    fetch(url + path, myInit)
      .then((res) => {
        if (!res.ok)
          return res.json().then((err) => {
            throw err;
          });
        else return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          alert(error.error.message);
          reject(error);
        }
      );
  });
}

export function methodDelete(path) {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    let myHeaders = new Headers();
    let myInit = {
      headers: myHeaders,
      signal: controller.signal,
      method: "DELETE",
    };
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("DOLAPIKEY", localStorage.getItem("DOLAPIKEY"));

    fetch(url + path, myInit)
      .then((res) => {
        if (!res.ok)
          return res.json().then((err) => {
            throw err;
          });
        else return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
  });
}
