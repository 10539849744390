import { useEffect, useState } from "react";
import BonDeLocationTable from "../components/dashboard/BonDeLocationsTable";
import ModalCreateBL from "../components/dashboard/CreatBLModal";
import Structure from "../components/navigation/Structure";
import SearchBar from "../components/UI/SearchBar";
import Select from "../components/UI/Select";
import { Spinner } from "../components/UI/Spinner";
import { methodGet } from "../providers/ApiProvider";

let agencies = null;
export default function Dashboard() {
  const [bons, setBons] = useState(null);
  const [showDuplicate, setShowDuplicate] = useState(null);
  const [bonsFiltered, setBonsFiltered] = useState(null);
  const [agencyOptions, setAgencyOptions] = useState(agencies);
  const [search, setSearch] = useState("");
  const [statutSelected, setStatutSelected] = useState(null);
  const [agencySelected, setAgencySelected] = useState(null);
  const [createBlModal, setCreateBlModal] = useState(false);

  useEffect(() => {
    if (bons == null)
      methodGet("/bonlocationapi/rentalagreements/allOpen")
        .then((res) => {
          res = res.filter((e) => e.status_id < 6);
          setBons(res);
        })
        .catch((err) => {
          if (err.error.code === 404) setBons([]);
        });
  }, [bons]);

  useEffect(() => {
    if (!agencyOptions)
      methodGet("/bonlocationapi/agency").then((res) => {
        setAgencyOptions(res);
        agencies = res;
      });
  }, [agencyOptions]);

  useEffect(() => {
    if (bons === null) return;
    let filtered = bons.filter(
      (e) =>
        (e.societe.nom && e.societe.nom.toLowerCase().includes(search.toLowerCase())) ||
        (e.ref && e.ref.toLowerCase().includes(search.toLowerCase())) ||
        (e.ref_order && e.ref_order.toLowerCase().includes(search.toLowerCase())) ||
        (e.applicant && e.applicant.toLowerCase().includes(search.toLowerCase())) ||
        (e.agency.name && e.agency.name.toLowerCase().includes(search.toLowerCase())) ||
        (e.name_agent && e.name_agent.toLowerCase().includes(search.toLowerCase()))
    );
    if (statutSelected !== null) filtered = filtered.filter((e) => e.status_id === statutSelected.value);
    if (agencySelected !== null) filtered = filtered.filter((e) => e.agency.id === agencySelected.value);
    setBonsFiltered(filtered);
  }, [bons, search, statutSelected, agencySelected]);

  return (
    <>
      <Structure
        title={"Tableau de bord"}
        displayButton={true}
        textButton="Creer bon de location"
        onClickButton={() => setCreateBlModal(true)}
        headerElements={() => (
          <>
            {agencyOptions && (
              <Select
                options={[
                  ...agencyOptions.map((e) => {
                    return { value: e.id, label: e.name };
                  }),
                ]}
                placeholder={"Agence . . ."}
                onChange={(e) => setAgencySelected(e)}
              />
            )}
            <Select
              options={[
                { value: 1, label: "Nouveau" },
                { value: 2, label: "p. Parti" },
                { value: 3, label: "Parti" },
                { value: 4, label: "p. Revenu" },
                { value: 5, label: "Revenu" },
              ]}
              placeholder={"Statut . . ."}
              onChange={(e) => setStatutSelected(e)}
            />
            <SearchBar id="search_dashboard" placeholder="Rechercher . . ." onChange={(value) => setSearch(value)} />
          </>
        )}
      >
        <div className="mt-10">
          {!bonsFiltered && <Spinner />}
          {bonsFiltered && (
            <BonDeLocationTable
              onDuplicate={(id) => {
                setShowDuplicate(id);
                setCreateBlModal(true);
              }}
              bons={bonsFiltered}
              search={search}
            />
          )}
        </div>
      </Structure>
      {createBlModal && (
        <ModalCreateBL
          open={createBlModal}
          setOpen={(show) => {
            setCreateBlModal(show);
            if (show === false && showDuplicate !== null) setShowDuplicate(null);
          }}
          duplicate={showDuplicate}
        />
      )}
    </>
  );
}
