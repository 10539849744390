export function PrimaryButton({ text, onClick, ref = null, ...props }) {
  return (
    <button
      type="button"
      className="inline-flex w-full justify-center rounded-md border border-transparent bg-p-purple dark:bg-pd-purple px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 items-center focus:outline-none sm:w-auto sm:text-sm"
      onClick={onClick}
    >
      {text}
      {props.children}
    </button>
  );
}

export function PrimaryButtonOutlined({ text, onClick, ...props }) {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center rounded-md border border-p-purple dark:border-pd-purple px-4 md:px-5 py-1 md:py-2 font-medium text-p-purple dark:text-pd-purple shadow-sm hover:shadow hover:bg-indigo-200 hover:bg-opacity-20 focus:outline-none text-xs md:text-sm"
      onClick={onClick}
    >
      {text}
      {props.children}
    </button>
  );
}

export function SecondaryOutlineButton({ text, onClick, reference = null, ...props }) {
  return (
    <button
      type="button"
      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
      onClick={onClick}
      ref={reference}
    >
      {text}
      {props.children}
    </button>
  );
}

export function SuccesButton({ text, onClick, disabled = false, ...props }) {
  return (
    <button
      type="button"
      disabled={disabled}
      className="flex items-center w-full disabled:opacity-60 justify-center rounded-md bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-500 dark:hover:bg-green-700 focus:outline-none sm:w-auto sm:text-sm"
      onClick={onClick}
    >
      {text}
      {props.children}
    </button>
  );
}

export function DeleteButton({ text, onClick, ...props }) {
  return (
    <button
      type="button"
      className="flex items-center w-full justify-center rounded-md  bg-p-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
      onClick={onClick}
    >
      {text}
      {props.children}
    </button>
  );
}
