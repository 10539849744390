import moment from "moment/moment";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getContratStatut } from "../../pages/Contrat";
import { getHighlightedText } from "../../providers/Functions";

export default function BonDeLocationTable({ bons, pagination = "30", onDuplicate, onRowDoubleClicked = null, search = "" }) {
  const navigate = useNavigate();
  let columns = [
    {
      name: "Statut",
      selector: (row) => row.status_id,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents" className={"px-3 whitespace-nowrap py-1 font-bold rounded-full text-white " + getContratStatut(row.status_id).color}>
            {getContratStatut(row.status_id).name}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Nom Client",
      selector: (row) => row.societe.nom,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{getHighlightedText(row.societe.nom, search)}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "N° du BL",
      selector: (row) => row.ref,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{getHighlightedText(row.ref, search)}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Réf. Commande",
      selector: (row) => row.ref_order,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{getHighlightedText(row.ref_order, search)}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Demandeur",
      selector: (row) => row.applicant,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{getHighlightedText(row.applicant, search)}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_date,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{moment(row.created_date * 1000).format("DD/MM/YYYY")}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Agence",
      selector: (row) => row.agency.name,
      sortable: true,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{getHighlightedText(row.agency.name, search)}</div>
        </div>
      ),
    },
    {
      name: "Nom Agent",
      selector: (row) => row.name_agent,
      sortable: true,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">{getHighlightedText(row.name_agent, search)}</div>
        </div>
      ),
    },
    {
      name: "Duplicate",
      selector: (row) => row.id,
      sortable: false,
      cell: (row, index, column, id) => (
        <div id={id} data-column-id={column} className="transitionsc-hKMtZM sc-eCYdqJ sc-jSMfEi jfrgbA bnAwAJ bMgaAx rdt_TableCell" data-tag="allowRowEvents">
          <svg onClick={() => onDuplicate(row.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-4 cursor-pointer">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        </div>
      ),
    },
  ];

  let isDarkMode = localStorage.getItem("theme") === "dark";
  let styles = {
    table: {
      style: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#111827" : "#ffffff",
      },
    },
    header: {
      style: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#111827" : "#ffffff",
      },
    },
    head: {
      style: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
      },
    },
    headRow: {
      style: {
        backgroundColor: isDarkMode ? "#111827" : "#ffffff",
        borderBottomColor: isDarkMode ? "#374151" : "#D1D5DB",
      },
    },
    rows: {
      style: {
        backgroundColor: isDarkMode ? "#111827" : "#ffffff",
        color: isDarkMode ? "#F3F4F6" : "#374151",
        "&:not(:last-of-type)": {
          borderBottomColor: isDarkMode ? "#374151" : "#D1D5DB",
        },
      },
      selectedHighlightStyle: {
        // use nth-of-type(n) to override other nth selectors
        "&:nth-of-type(n)": {
          color: isDarkMode ? "#F3F4F6" : "#374151",
          backgroundColor: isDarkMode ? "#1F2937" : "#F3F4F6",
        },
      },
      highlightOnHoverStyle: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#1F2937" : "#F3F4F6",
        borderBottomColor: isDarkMode ? "#374151" : "#D1D5DB",
      },
      stripedStyle: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#1F2937" : "#F3F4F6",
      },
    },
    pagination: {
      style: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#111827" : "#ffffff",
        paddingTop: "0px",
        transition: "all",
        transitionDuration: "500ms",
        animation: "ease-in-out",
      },
      pageButtonsStyle: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#1F2937" : "#F3F4F6",
        fill: isDarkMode ? "#F3F4F6" : "#374151",
        "&:disabled": {
          color: isDarkMode ? "#1F2937" : "#F3F4F6",
          backgroundColor: isDarkMode ? "#111827" : "#ffffff",
          fill: isDarkMode ? "#1F2937" : "#F3F4F6",
        },
        "&:hover:not(:disabled)": {
          backgroundColor: isDarkMode ? "#1F2937" : "#F3F4F6",
          fill: isDarkMode ? "#F3F4F6" : "#374151",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: isDarkMode ? "#1F2937" : "#F3F4F6",
          fill: isDarkMode ? "#F3F4F6" : "#374151",
        },
      },
    },
    noData: {
      style: {
        color: isDarkMode ? "#F3F4F6" : "#374151",
        backgroundColor: isDarkMode ? "#111827" : "#ffffff",
      },
    },
  };

  return (
    <DataTable
      columns={columns}
      data={bons}
      striped
      pagination
      highlightOnHover
      defaultSortFieldId={1}
      paginationPerPage={pagination}
      customStyles={styles}
      onRowClicked={(row) => {
        if (window.innerWidth < 768) navigate("/contrat/" + row.id);
      }}
      onRowDoubleClicked={(row) => {
        navigate("/contrat/" + row.id);
      }}
    />
  );
}
