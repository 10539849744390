import moment from "moment";
import { useEffect, useState } from "react";
import { methodGet, methodPost, methodPut } from "../../providers/ApiProvider";
import { DeleteButton, SecondaryOutlineButton, SuccesButton } from "../UI/Buttons";
import EditableValue from "../UI/EditableValue";
import Modal from "../UI/Modal";
import Select from "../UI/Select";
import { Spinner } from "../UI/Spinner";
import ConstatForm from "./ConstatForm";

export default function MultiConstatModal({ contrat, open, setOpen, onAdded }) {
  const [step, setStep] = useState(0);
  const [products, setProducts] = useState(contrat.products);
  const [show, setShow] = useState(contrat.products[0].startReport === null ? "startReport" : "returnReport");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (step < products.length && products[step]["array_options"] === undefined && products[step].product_id) {
      setLoading(true);
      setShow(products[step].startReport === null ? "startReport" : "returnReport");
      methodGet("/products/" + products[step].product_id).then((res) => {
        let modifProducts = [...products];
        if (!modifProducts[step]["report"]) modifProducts[step]["report"] = {};
        if (res.array_options && res.array_options !== [] && show === "startReport") {
          modifProducts[step].array_options = Object.keys(res.array_options).map((k) => {
            return { [k.split("options_")[1]]: res.array_options[k] };
          });
          if (modifProducts[step].array_options.length > 0) {
            modifProducts[step].array_options = modifProducts[step].array_options.reduce((a, b) => {
              return { ...a, ...b };
            });
            modifProducts[step]["report"]["exterior_cleanliness"] = modifProducts[step].array_options["proprete_exterieure"];
            modifProducts[step]["report"]["interior_cleanliness"] = modifProducts[step].array_options["proprete_interieure"];
            modifProducts[step]["report"]["lights_windows_mirrors"] = modifProducts[step].array_options["feux_vitres_glaces"];
            modifProducts[step]["report"]["fuel"] = modifProducts[step].array_options["carburant"];
            modifProducts[step]["report"]["body"] = modifProducts[step].array_options["carroserie"];
            modifProducts[step]["report"]["document"] = modifProducts[step].array_options["documents"];
            modifProducts[step]["report"]["mileage"] =
              Math.round((modifProducts[step].mileage ? modifProducts[step].mileage : modifProducts[step].array_options["kilometrage"]) * 100) / 100;
            modifProducts[step]["report"]["hours"] =
              Math.round((modifProducts[step].hours ? modifProducts[step].hours : modifProducts[step].array_options["heures"]) * 100) / 100;
          } else {
            modifProducts[step]["report"]["mileage"] = Math.round(modifProducts[step].mileage * 100) / 100;
            modifProducts[step]["report"]["hours"] = Math.round(modifProducts[step].hours * 100) / 100;
          }
        } else if (show === "returnReport") {
          modifProducts[step]["report"]["exterior_cleanliness"] = modifProducts[step]["startReport"]["exterior_cleanliness"];
          modifProducts[step]["report"]["interior_cleanliness"] = modifProducts[step]["startReport"]["interior_cleanliness"];
          modifProducts[step]["report"]["lights_windows_mirrors"] = modifProducts[step]["startReport"]["lights_windows_mirrors"];
          modifProducts[step]["report"]["fuel"] = modifProducts[step]["startReport"]["fuel"];
          modifProducts[step]["report"]["body"] = modifProducts[step]["startReport"]["body"];
          modifProducts[step]["report"]["document"] = modifProducts[step]["startReport"]["document"];
          modifProducts[step]["report"]["mileage"] = modifProducts[step]["startReport"]["mileage"];
          modifProducts[step]["report"]["hours"] = modifProducts[step]["startReport"]["hours"];
          modifProducts[step].array_options = modifProducts[step]["report"];
        }
        setProducts(modifProducts);
        setLoading(false);
      });
    } else if (step < products.length && !products[step]["report"]) {
      let modifProducts = [...products];
      modifProducts[step]["report"] = {};
      setProducts(modifProducts);
    }
  }, [step, products]);

  function setConstat(report) {
    let modifProducts = [...products];
    modifProducts[step].report = report;
    setProducts(modifProducts);
  }

  async function handleUploadReports(e) {
    let date = document.getElementById("date_constat").value;
    if (date === "") return alert("Veuillez saisir la date");
    setLoading(true);
    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      if (product.report === undefined) product.report = {};
      await methodPost("/bonlocationapi/rentalagreements/report/" + product.id + "/" + (show === "startReport" ? 1 : 2), {
        ...product.report,
        date: moment(date).toDate().getTime() / 1000,
      });

      if (show === "startReport")
        await methodPut("/bonlocationapi/product/" + product.id, {
          release_date: moment(date).toDate().getTime() / 1000,
          hours: product.report.hours,
          mileage: product.report.mileage,
          return_date:
            moment(date).toDate().getTime() >
            moment(product.return_date * 1000)
              .toDate()
              .getTime()
              ? moment(date).set({ h: 18, minutes: 0 }).toDate().getTime() / 1000
              : product.return_date,
        });
      else
        await methodPut("/bonlocationapi/product/" + product.id, {
          return_date: moment(date).toDate().getTime() / 1000,
        });
      if ((show === "startReport") === false && product.product_id !== null)
        await methodPut("/products/" + product.product_id, {
          array_options: {
            options_proprete_exterieure: product.report.exterior_cleanliness,
            options_proprete_interieure: product.report.interior_cleanliness,
            options_carroserie: product.report.body,
            options_feux_vitres_glaces: product.report.lights_windows_mirrors,
            options_documents: product.report.document,
            options_carburant: product.report.fuel,
            options_kilometrage: product.report.mileage,
            options_heures: product.report.hours,
          },
        });
    }
    setLoading(false);
    onAdded();
    setOpen(false);
  }

  return (
    <Modal
      title={products[step] ? products[step].name + "( ref. " + products[step].reference + " )" : "Ajouter Constat"}
      showFooter={false}
      open={open}
      setOpen={setOpen}
    >
      {loading && <Spinner />}
      {!loading && step < products.length && products[step].report !== undefined && (
        <ConstatForm
          key={"form_" + step}
          product={products[step]}
          constat={products[step].report}
          setConstat={setConstat}
          show={show}
          setShow={setShow}
          isMulti={true}
        />
      )}
      {!loading && step === products.length && (
        <div className="w-64 mb-20 mt-4">
          <EditableValue
            key={"date_constat"}
            id={"date_constat"}
            title="Date"
            type="datetime-local"
            editing={true}
            defaultValue={
              (show === "startReport" ? products[0].release_date : products[0].return_date) !== ""
                ? moment((show === "startReport" ? products[0].release_date : products[0].return_date) * 1000).format("YYYY-MM-DD HH:mm")
                : null
            }
            onChange={(e) => {}}
            small={false}
          />
        </div>
      )}
      <div className="flex items-center justify-around w-2/4 mx-auto mt-20">
        {step > 0 && (
          <div onClick={() => (step - 1 < 0 ? setStep(0) : setStep(step - 1))} className="w-36 flex justify-center items-center space-x-2 group cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path
                fillRule="evenodd"
                d="M7.28 7.72a.75.75 0 010 1.06l-2.47 2.47H21a.75.75 0 010 1.5H4.81l2.47 2.47a.75.75 0 11-1.06 1.06l-3.75-3.75a.75.75 0 010-1.06l3.75-3.75a.75.75 0 011.06 0z"
                clipRule="evenodd"
              />
            </svg>
            <p className="font-bold group-hover:underline">Précédent</p>
          </div>
        )}
        {step === 0 && <SecondaryOutlineButton text="Fermer" onClick={() => setOpen(false)} />}
        <div className="relative flex items-center space-x-2">
          <div
            style={{ left: 8 + step * 20 + "px" }}
            className={"h-3 w-3 absolute transition-all ease-in-out duration-300 rounded-full " + (show === "startReport" ? "bg-green-500" : "bg-p-red")}
          />
          {products &&
            products.map((e, k) => <div key={k + "_point"} className="h-3 w-3 transition-all duration-300 rounded-full bg-gray-300 dark:bg-gray-700" />)}
          <div className="h-3 w-3 transition-all duration-300 rounded-full bg-gray-300 dark:bg-gray-700" />
        </div>
        {step < products.length && (
          <div
            onClick={() => (step + 1 > products.length ? setStep(products.length - 1) : setStep(step + 1))}
            className={"w-36 flex justify-center items-center space-x-2 group cursor-pointer " + (show === "startReport" ? "text-green-500" : "text-p-red")}
          >
            <p className="font-bold group-hover:underline">Suivant</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path
                fillRule="evenodd"
                d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
        {step === products.length && show === "startReport" && <SuccesButton text="Finaliser" onClick={handleUploadReports} />}
        {step === products.length && show !== "startReport" && <DeleteButton text="Finaliser" onClick={handleUploadReports} />}
      </div>
    </Modal>
  );
}
