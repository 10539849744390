import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButtonOutlined } from "../UI/Buttons";
import Menu from "./Menu";

export default function Structure({ title, displayButton = false, textButton, onClickButton, headerElements, ...props }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("DOLAPIKEY") === null) navigate("/login");
  }, []);

  return (
    <main className="flex">
      <Menu />
      <div className="h-screen ml-16 2xl:ml-0 p-4 w-full block overflow-auto">
        <div className="flex justify-between items-center border-b border-gray-200 py-2 w-full space-x-10">
          <div className="flex items-end space-x-5">
            <h1 className="font-semibold text-xl md:text-4xl">{title}</h1>
            {displayButton && <PrimaryButtonOutlined text={textButton} onClick={onClickButton} />}
          </div>
          {headerElements && <div className="flex items-center justify-end flex-grow space-x-2">{headerElements()}</div>}
        </div>
        {props.children}
      </div>
    </main>
  );
}
