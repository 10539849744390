import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpinnerSmall } from "../components/UI/Spinner";
import { methodGet } from "../providers/ApiProvider";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let search = window.location.search;
    if (search !== "" && search.split("?")[1].split("=")[0] === "DOLAPIKEY") {
      localStorage.setItem("DOLAPIKEY", search.split("?")[1].split("=")[1]);
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-20 w-auto" src="./images/logo_gray.png" alt="logo company" />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tigh">Connectez-vous à votre compte</h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              let res = await methodGet("/login?login=" + e.target[0].value + "&password=" + e.target[1].value).catch((err) => {
                setLoading(false);
                setErrorMessage(err.error.message);
              });
              localStorage.setItem("DOLAPIKEY", res.success.token);
              setLoading(false);
              navigate("/");
            }}
          >
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="login" className="sr-only">
                  Nom d'utilisateur
                </label>
                <input
                  id="login"
                  name="login"
                  type="login"
                  autoComplete="login"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border dark:bg-gray-800 border-gray-300 px-3 py-2 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none sm:text-sm"
                  placeholder="Nom d'utilisateur"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Mot de passe
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border dark:bg-gray-800 border-gray-300 px-3 py-2 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none sm:text-sm"
                  placeholder="Mot de passe"
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-p-purple dark:bg-gray-800" />
                <label htmlFor="remember-me" className="ml-2 block text-sm">
                  Se souvenir de moi
                </label>
              </div>
            </div>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-p-purple py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                S'identifier
              </button>
            </div>
          </form>
          {loading && <SpinnerSmall />}
        </div>
      </div>
    </>
  );
}
