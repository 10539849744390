import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PrimaryButton, SecondaryOutlineButton } from "./Buttons";
import { Spinner } from "./Spinner";
import { useEffect } from "react";

export default function Modal({
  title,
  loading = false,
  showFooter = true,
  open,
  setOpen,
  showActionButton = true,
  textActionButton = "Creer",
  onActtionButton,
  errorMessgae = null,
  ...props
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className=" h-screen p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-auto transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:mt-10 sm:mb-4 sm:w-full sm:max-w-4xl">
                <div className="bg-white dark:bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50 mb-4">
                    {title}
                  </Dialog.Title>
                  {loading && (
                    <div className="flex justify-center p-20">
                      <Spinner />
                    </div>
                  )}
                  {!loading && props.children}
                </div>
                {showFooter && (
                  <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:flex sm:justify-end sm:px-6 space-y-2 sm:space-y-0 sm:space-x-2">
                    {errorMessgae && <p className="text-red-500 text-sm mr-auto my-auto">{errorMessgae}</p>}
                    <SecondaryOutlineButton text={"Fermer"} onClick={() => setOpen(false)} reference={cancelButtonRef} />
                    {showActionButton && <PrimaryButton text={textActionButton} onClick={onActtionButton} />}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function ModalConfrimation() {
  const [data, setData] = useState({ open: false });

  useEffect(() => {
    setDataModal = setData;
  }, []);

  return (
    <Modal
      open={data.open}
      textActionButton="Confirmer"
      setOpen={(e) => {
        setData({ open: false });
        data.onCancel();
      }}
      showActionButton={true}
      title={data.title}
      onActtionButton={() => {
        data.onConfirm();
        setData({ open: false });
      }}
    >
      <p className="mt-4 mb-20">{data.message}</p>
    </Modal>
  );
}

var setDataModal = null;

export function askConfirmationModal(title, message, onCancel, onConfirm) {
  setDataModal({ open: true, title: title, message: message, onCancel: onCancel, onConfirm: onConfirm });
}
