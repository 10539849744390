import { useNavigate } from "react-router-dom";

export default function MenuElement({ path, onClick = null, selected, ...props }) {
  const navigate = useNavigate();
  return (
    <li onClick={() => {if(onClick !== null) onClick()}} className="mx-2 cursor-pointer">
      <a
        className={
          "flex items-center px-3 2xl:px-5 py-2 overflow-hidden text-ellipsis space-x-4 whitespace-nowrap transition duration-300 ease-in-out " +
          (selected
            ? "border border-p-purple dark:border-pd-purple text-p-purple dark:text-pd-purple font-medium rounded-lg 2xl:rounded-xl"
            : "hover:text-indigo-600 hover:bg-blue-50 dark:hover:bg-indigo-900 text-gray-500 dark:text-gray-300 rounded")
        }
        onClick={() => navigate(path)}
        data-mdb-ripple="true"
        data-mdb-ripple-color="primary"
      >
        {props.children}
      </a>
    </li>
  );
}
