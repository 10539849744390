import moment from "moment";
import { useEffect, useState } from "react";
import BonDeLocationTable from "../components/dashboard/BonDeLocationsTable";
import Structure from "../components/navigation/Structure";
import SearchBar from "../components/UI/SearchBar";
import Select from "../components/UI/Select";
import { Spinner } from "../components/UI/Spinner";
import { methodGet } from "../providers/ApiProvider";

let agencies = null;

export default function History() {
  const [bons, setBons] = useState(null);
  const [bonsFiltered, setBonsFiltered] = useState(null);
  const [search, setSearch] = useState("");
  const [agencyOptions, setAgencyOptions] = useState(agencies);
  const [agencySelected, setAgencySelected] = useState(null);
  const [dates, setDates] = useState(["", ""]);

  useEffect(() => {
    if (bons == null)
      methodGet("/bonlocationapi/rentalagreements/all")
        .then((res) => {
          res = res.filter((e) => e.status_id >= 6);
          setBons(res);
        })
        .catch((err) => {
          if (err.error.code === 404) setBons([]);
        });
  }, [bons]);

  useEffect(() => {
    if (!agencyOptions)
      methodGet("/bonlocationapi/agency").then((res) => {
        setAgencyOptions(res);
        agencies = res;
      });
  }, [agencyOptions]);

  useEffect(() => {
    if (bons === null) return;
    let filtered = bons.filter(
      (e) =>
        (e.societe.nom && e.societe.nom.toLowerCase().includes(search.toLowerCase())) ||
        (e.ref && e.ref.toLowerCase().includes(search.toLowerCase())) ||
        (e.ref_order && e.ref_order.toLowerCase().includes(search.toLowerCase())) ||
        (e.applicant && e.applicant.toLowerCase().includes(search.toLowerCase())) ||
        (e.agency.name && e.agency.name.toLowerCase().includes(search.toLowerCase())) ||
        (e.name_agent && e.name_agent.toLowerCase().includes(search.toLowerCase()))
    );

    if (agencySelected !== null) filtered = filtered.filter((e) => e.agency.id === agencySelected.value);
    if (dates[0] !== "" && dates[1] !== "") {
      filtered = filtered.filter((e) => {
        return moment(dates[0]).toDate().getTime() < e.created_date * 1000 && e.created_date * 1000 < moment(dates[1]).toDate().getTime();
      });
    }

    setBonsFiltered(filtered);
  }, [bons, search, agencySelected, dates]);

  return (
    <Structure
      title={"Historique"}
      headerElements={() => (
        <>
          <div className="flex space-x-2 items-center mr-auto">
            <p className="text-sm">Du : </p>
            <input type="date" className=" bg-gray-50 dark:bg-gray-900 rounded-xl text-sm" onChange={(e) => setDates([e.target.value, dates[1]])} />
            <p className="text-sm">Au : </p>
            <input type="date" className=" bg-gray-50 dark:bg-gray-900 rounded-xl text-sm" onChange={(e) => setDates([dates[0], e.target.value])} />
          </div>
          {agencyOptions && (
            <Select
              options={[
                ...agencyOptions.map((e) => {
                  return { value: e.id, label: e.name };
                }),
              ]}
              placeholder={"Agence . . ."}
              onChange={(e) => setAgencySelected(e)}
            />
          )}
          <SearchBar id="search_dashboard" placeholder="Rechercher . . ." onChange={(value) => setSearch(value)} />
        </>
      )}
    >
      <div className="mt-10">
        {!bonsFiltered && <Spinner />}
        {bonsFiltered && <BonDeLocationTable bons={bonsFiltered} search={search} />}
      </div>
    </Structure>
  );
}
